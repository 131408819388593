/* You can add global styles to this file, and also import other style files */


:root {
    --primary-light-color: #4169e1;
    --primary-color: #4169e1;
    --secondary-color: #81bc00;
    --grey-color: #f7f7f7;
    --text-color: #333333;
    --white-color: #ffffff;
    --warn-color: #F1536E;
    --green-color: #00C689;
    --orange-color: #f7682e;
    --yellow-color: #FDA006;
  }


html, body { height: 100%; }
body { background-color: #f3f4f7; margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
button:focus{
    outline: none!important;
}

*:focus{
  outline: none!important;
}
.input:-internal-autofill-selected{
  background-color: transparent!important;
}

.backdropClass{
  background-color: black;
}

.panelClassFeedbackGenerationForm .mat-dialog-container{
  background-color: #eaeded;
  overflow-x: hidden !important ;
}


/* global setting for tooltip */
.toolTip-Text{

}

.mat-drawer-content{
  background: #dcdcdc!important;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* margin: 0px!important; */
}

.full-width{
    width: 100%;
}

.border-primary{
    border: 1px solid var(--primary-color)!important;
}

.text-color-white{
  color: white;
}
.bg-color-primary{
  background-color: var(--primary-color);
}
.text-color-primary{
  color: var(--primary-color);
}
.border-color-primary{
  border: 2px solid var(--primary-color);
}
.bg-color-warning{
  background-color: var(--warn-color);
}
.text-color-warning{
  color: var(--warn-color);
}
.border-color-warning{
  border: 2px solid var(--warn-color);
}
.bg-color-green{
  background: var(--green-color);
}
.text-color-green{
  color: var(--green-color);
}
.border-color-green{
  border: 2px solid var(--green-color);
}
.bg-color-yellow{
  background: var(--yellow-color);
}
.text-color-yellow{
  color: var(--yellow-color);
}
.border-color-yellow{
  border: 2px solid var(--yellow-color);
}

.p-rating .p-rating-icon.pi-star {
  color: var(--primary-color)!important;
}

* ::-webkit-scrollbar {
  width: 10px;
  opacity: 0.2;
}

/* Track */
* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 10px;
  opacity: 0.2;
}

/* Handle */
* ::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  opacity: 0.2;
}

/* Handle on hover */
* ::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
  opacity: 0.4;
}

/*CSS for dialog boxes*/
/*START*/
.mat-drawer-container{
    background: #FFF!important;
}

.mat-dialog-container{
    padding: 0px!important;
}

.dialog-cancel-button{
    background: var(--warn-color);
    color: #FFF;
}

.dialog-save-button{
    background: var(--primary-color);
    color: #FFF;
}
.dialog-header{
    font-size: large;
    background: var(--primary-color);
    color: #FFF;
}

.dialog-main{
    height: 100%;
    position: relative;
}

.dialog-footer{
    bottom: 10px;
    right: 0;
    position: absolute;
}
/*ENDS*/

/**FG-Card - CSS**/
.img-col{
    border-right: 1px solid #e9ecef;
  }
  
  .fg-card-header{
    font-size: 20px!important;
    line-height: 28px!important;
    transition: all .5s;
  }
  
  .fg-card-text{
    display: block;
    font-size: smaller;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 2px 0;
  }
  
  .fg-card{
    background: #FFF;
    height: 210px;
    position: relative;
    border: 1px solid var(--primary-color);
  }

  .fg-card-feedback{
    background: #FFF;
    height: 150px;
    width: 230px;
    position: relative;
    border: 1px solid var(--primary-color);
  }
  
  .fg-card:hover{
    -webkit-box-shadow: 0 5px 20px -10px var(--primary-color);
    box-shadow: 0 5px 20px -10px var(--primary-color);
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }
   
  .fg-card:hover .fg-card-header{
    color: var(--primary-color);
  }
  
  .fg-card-footer{
    position: absolute;
    bottom: 5px;
  }
  /**FG-Card - CSS**/

  /* NEXT */

  /* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

.clearfix{clear:both;}
.pointer{cursor: pointer;}
.ucfirst{text-transform: capitalize;}
.min300{min-height:300px}
.app-box{
    padding: 10px;
    margin-bottom:20px;
    height: 230px;
    background: #b5e8d4;
    overflow: hidden;
    box-shadow: 1px 1px 4px #ccc;
}
.app-box:hover{
    box-shadow: 1px 1px 4px #999;
}
.app-box .remove{
    position: absolute;
    right: 21px;
    top: 0px;
    color: #545454;
    display:none;
}
.app-box:hover .remove{
    display:block;
}
.inline-btn{
    background: #00b970;
    padding: 1px 5px;
    color: white;
    display: inline-block;
    margin-bottom: 5px;
}
.box{
    padding: 10px;
    margin-bottom:10px;
    overflow: auto;
    box-shadow: 1px 1px 4px #ddd;
}
.form-group .form-control{
    font-size:inherit;
    background-color: inherit;
}

.dndDropArea{
    padding: 10px;
    /* border: dotted #ddd 2px; */
}

.dndDropArea.empty{
    min-height:200px;
    border: dotted #ddd 2px;
}

.field{
    padding:0px;
    margin-bottom:0px;
    position: relative;
}
.field:hover{
    box-shadow: 1px 1px 4px #ddd;
}
.toggle-Wrapper{
    padding: 15px;
    background: #d6d6d629;
}
.dndHandle{
    cursor: pointer;
    color: #999;
}
.dndDraggingSource{
    opacity:0.3;
}
.dndPlaceholder{
    background: rgb(206, 238, 238); border-radius: 2px; padding: 20px;
    margin-bottom:10px;
}
.field .fa{
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 24px;
    vertical-align: middle;

}
.red{color:#d9534f}
.fa-trash{color:#d9534f}
.marginT60{
    margin-top:60px;
}

.color-red{color:red;}
.report-block{
    padding:10px;
    background:#f8f8f8;
    margin-bottom:20px;
}
.report-block .row:nth-child(even){
    background:#fff;
}

.ftheme{
    width:100%;
}

.dndDropArea .field .fa{
    opacity:0;
}
.dndDropArea .field:hover .fa{
    opacity:1;
}

.colorpick{
    height: 25px;
    background: white;
    border: none;
}

.carousel-wrap {
  width: 1000px;
  margin: auto;
  position: relative;
}

/* .carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  /* background: no-repeat 50%/100% 100%; */
/* } */

.carousel-indicators .active {
    opacity: 1;
} */


.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: #00BFD5;
  float: right;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: #00BFD5;
}


.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #00BFD5;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 1;
    transition: opacity .6s ease;
}